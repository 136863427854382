
import { Options, Vue } from 'vue-class-component';
import { EstimationClient, BagEstimationClient } from '@/services/Services';
import { baseUrl } from '@/config';
import BestBagCombinationTable from '../components/bestBagCombinationTable.vue';
import SuggestionFiltersModal from '../modals/suggestionFiltersModal.vue';
import * as OM from '@/Model';
import filters from '@/filters';

@Options({
    components: {
        BestBagCombinationTable
    }
})
export default class BestBagCombination extends Vue {

    estimationIdentifier: string = "";
    originalEstimation: OM.BestBagCombination = new OM.BestBagCombination();
    bestCombination: OM.BestBagCombination = new OM.BestBagCombination();

    filters: OM.BestCombinationFilters = new OM.BestCombinationFilters();

    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.filters.estimationIdentifier = this.estimationIdentifier;
        this.init();
    }

    init() {
        Promise.all([
            BagEstimationClient.getBagEstimationData(this.estimationIdentifier),
            EstimationClient.bestBagCo2EmissionsConfigurationByGarment(this.filters)
        ])
        .then(xs => {
            this.originalEstimation = xs[0];
            this.bestCombination = xs[1];
        })
    }

    openFilters() {
        this.$opModal.show(SuggestionFiltersModal, {
            garmentIdentifier: this.originalEstimation.bestBagEstimation.bagData.garment.identifier,
            activeFilters: this.filters,
            showGSMSuggestion: false,
            callback: (keepUsingEstimationRawMaterialsString: string, useProvidersString: string, keepUsingEstimationGSMString: string) => {
                this.filters.keepUsingOriginalEstimationRawMaterials = keepUsingEstimationRawMaterialsString == true.toString();
                this.filters.useUserProviders = useProvidersString == true.toString();
                this.filters.useSameGrams = keepUsingEstimationGSMString == true.toString();

                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    downloadBestCombinationPDF() {
        var actualDate = filters.date(new Date(), "dd-MM-YYYY", false);
        var fileName = "best_combination_" + this.bestCombination.bestBagEstimation.sku + "_" + actualDate;
        this.$utils.downloadBestCombinationPdf(baseUrl + "api/Estimation/DownloadBestBagCombinationPDF", this.bestCombination.identifier, fileName);
    }

}
